import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import "./styles.scss";

import ZZLP1 from "../../files/zzlp/podavane_signal_narushenie.pdf";
import ZZLP2 from "../../files/zzlp/registrirane_narushenie.pdf";

import gdpr_candidates from "../../files/gdpr/GDPR_candidates.pdf";
import gdpr_clients from "../../files/gdpr/GDPR_clients.pdf";
import gdpr_form from "../../files/gdpr/GDPR_form.odt";


import cookies from "../../files/general/cookies_policy.pdf";
import license from "../../files/general/license_2021.pdf";
import complaintPolicy from "../../files/general/complaint_policy.pdf";
import info325 from "../../files/general/info_325.pdf";
import protocol from "../../files/general/dvustranen.pdf";
import protocolManual from "../../files/general/dvustranen_ukazaniq.pdf";
import euro_acceptance from "../../files/general/euro_accept_info.pdf";


const mainDocs = [
  {
    name: "Политика за бисквитките",
    url: cookies,
  },
  {
    name: "Лиценз",
    url: license,
  },
  {
    name: "Информация по чл.325 от КЗ",
    url: info325,
  },
  {
    name: "Съобщение към потребителите за приемане на еврото",
    url: euro_acceptance,
  },
  {
    name: "Двустранен констативен протокол",
    url: protocol,
  },
  {
    name: "Указания за попълване на Двустранен констативен протокол",
    url: protocolManual,
  },
  {
    name: "Политика за жалбите",
    url: complaintPolicy,
  },
];
const offDocs = [
  {
    name: "Уведомление за Поверителност за кандидати за Работа",
    url: gdpr_candidates,
  },
  {
    name: "Уведомление за Поверителност за Клиентите",
    url: gdpr_clients,
  },
  {
    name: "Заявление за упражняване на права по чл.15-22 от Регламент (ЕС) 2016/679",
    url: gdpr_form,
  },
];

const otherDocs = [
  {
    name: "Формуляр за регистриране на сигнал",
    url: ZZLP2,
  },
  {
    name: "Подаване на сигнали за нарушения Амарант Кар",
    url: ZZLP1,
  },
];

const Documents = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="documents">
      <div className="documents-container">
        <div className="left-documents-container">
          <div className="main-documents">ОСНОВНИ ДОКУМЕНТИ</div>
          <div className="documents-list">
            {mainDocs.map((doc) => {
              return (
                <div className="doc-container" onClick={() => window.open(doc.url)}>
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue"></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="left-documents-container" style={{ margin: "0% 3%" }}>
          <div className="main-documents">GDPR ДОКУМЕНТИ</div>
          <div className="documents-list">
            {offDocs.map((doc) => {
              return (
                <div className="doc-container" onClick={() => window.open(doc.url)}>
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue" onClick={() => window.open(doc.url)}></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="left-documents-container">
          <div className="main-documents">ДРУГИ ДОКУМЕНТИ</div>
          <div className="documents-list">
            {otherDocs.map((doc) => {
              return (
                <div className="doc-container" onClick={() => window.open(doc.url)}>
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Documents);
