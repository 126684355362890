import React, { Component, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./styles.scss";

const NavMain = ({}) => {
  return (
    <>
      <div className="download-container"></div>
      <div className="nav-main-wrap">
        <nav className="nav-main">
          <div className="item">
            <NavLink activeClassName="active" to="/subscriptions">
              АБОНАМЕНТИ
            </NavLink>
          </div>
          <div className="item">
            <NavLink activeClassName="active" to="/blog">
              НОВИНИ
            </NavLink>
          </div>
          <div className="item">
            <NavLink activeClassName="active" to="/offices">
              ОФИСИ
            </NavLink>
          </div>
          <div className="item">
            <NavLink className="item-bttn" to="/download-app">
              Свали приложението
            </NavLink>
          </div>
        </nav>
      </div>
    </>
  );
};

export default connect()(withRouter(NavMain));
