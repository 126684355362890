import { ofType, ActionsObservable } from 'redux-observable'
import { officesTypes, getOfficesSuccess } from '../actions'
import { switchMap, catchError } from 'rxjs/operators'
import { apiUrl } from '../config/settings'
import { ajax } from 'rxjs/ajax'


export const getOffices = (action$, state$) =>
  action$.pipe(
    ofType(officesTypes.GET_OFFICES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${apiUrl}/office`,
        method: 'GET',
        headers: '',
        body:JSON.stringify(payload)
      })
      .pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create(obs => {
            obs.next(getOfficesSuccess(response.payload?.filter(office => office?.name === "София, Централен офис")))
            payload.onSuccess(response.payload?.filter(office => office?.name === "София, Централен офис"));
            obs.complete()
          })
        }),
        catchError(err => {
            return ActionsObservable.create(obs => {
              console.error(err)
              obs.next({ type: officesTypes.GET_OFFICES_FAIL, payload: {} })
              obs.complete()
            })
          })
        )
    })
  )
